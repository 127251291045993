/* Modules */
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Link } from 'react-router-dom'

/* Dépendences */
import { productsService } from "../../_services/products.service"

/* Composants */
import Header from "../../composants/Header"
import TitreGammes from "../../composants/TitreGammes"
import Plus from "../../composants/Plus";
import Footer from "../../composants/Footer"
import BoutonScrollToTop from "../../composants/BoutonScrollToTop"

/* Images */
import DrapeauFrance from "../../assets_opti/produits/drapeauFrance_opti.webp"
import DrapeauEspagne from "../../assets_opti/produits/drapeauEspagne_opti.webp"
import DrapeauItalie from "../../assets_opti/produits/drapeauItalie_opti.webp"

/* Code */
export default function Destockage() {
    /* Récupération des données de l'URL */
    let UrlParams = useParams();
    let gamme = UrlParams.gammeStock;

    let [cards, setCards] = useState([]);
    let [catId, setCatId] = useState(null);
    let [pageIsLoading, setPageIsLoading] = useState(true);

    useEffect(() => {
        if (gamme === "carrelage") {
            setCatId(1);
        } else if (gamme === "parquet") {
            setCatId(36);
        } else {
            setCatId("");
        }
    }, [gamme]);

    useEffect(() => {
        if(catId !== null) {
            setPageIsLoading(true);
            productsService.getAllCards({
                categId: catId,
                available : true
            })
            .then((res) => {
                setCards(res.data.products);
                setPageIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            })
        }
    }, [catId]);

    /* On enlève les accessoires carrelage de la liste des produits */
    let selectedCards = [];

    if(cards.length > 0) {
        cards.map((product) => {
            if(product.category.id !== 92 && product.category.id !== 93 && product.category.id !== 94 && product.category.id !== 95 && product.category.id !== 96 && product.category.id !== 97 && product.category.id !== 98 && product.category.id !== 99 && product.category.id !== 100 && product.category
                .id !== 101 && product.category.id !== 102) {
                return selectedCards.push(product);
            } else {
                return null;
            }
        })
    }

    /* On utilise un switch/case pour déterminer quels boutons afficher selon la gamme sélectionnée */
    function RenderSwitch(gamme) {
        switch(gamme) {
            case 'carrelage-et-parquet':
                return (
                <>
                    <Link to="/en-stock/carrelage" className="boutonStock" key="carrelage-dispo">CARRELAGE EN STOCK</Link>
                    <Link to="/en-stock/parquet" className="boutonStock" key="parquet-dispo">PARQUET EN STOCK</Link>
                </>
                );
            case 'carrelage':
                return (
                <>
                    <Link to="../carrelage/sols-&-murs/pas-de-sous-categorie/en-stock" className="boutonStock" key="sols-et-murs-dispo">Sols & Murs</Link>
                    <Link to="../carrelage/les-faïences-murales/pas-de-sous-categorie/en-stock" className="boutonStock" key="les-faiences-dispo">Les Faïences</Link>
                    <Link to="../carrelage/les-extérieurs/pas-de-sous-categorie/en-stock" className="boutonStock" key="les-extérieurs-dispo">Les Extérieurs</Link>
                    <Link to="../carrelage/pierres-naturelles/pas-de-sous-categorie/en-stock" className="boutonStock" key="pierres-naturelles-dispo">Pierres Naturelles</Link>
                    <Link to="../carrelage/les-mosaïques/pas-de-sous-categorie/en-stock" className="boutonStock" key="les-mosaïques-dispo">Les Mosaïques</Link>
                    <Link to="../carrelage/accessoires-carrelage/pas-de-sous-categorie/en-stock" className="boutonStock" key="accessoires-carrelage-dispo">Accessoires Carrelage</Link>
                </>
            );
            case 'parquet':
                return (
                <>
                    <Link to="../parquet/massif/pas-de-sous-categorie/en-stock" className="boutonStock" key="massif-dispo">Massif</Link>
                    <Link to="../parquet/contrecollé/pas-de-sous-categorie/en-stock" className="boutonStock" key="contrecollé-dispo">Contrecollé</Link>
                    <Link to="../parquet/stratifié-&-pvc/pas-de-sous-categorie/en-stock" className="boutonStock" key="stratifié-&-pvc-dispo">Stratifié & Pvc</Link>
                    <Link to="../parquet/accessoires-parquet/pas-de-sous-categorie/en-stock" className="boutonStock" key="accessoires-parquet-dispo">Accessoires Parquet</Link>
                </>
            );
            default: 
                return null;
        }
    }

    /* Modification du titre et de la description de la page */
    useEffect(() => {
        document.title = `${gamme.split("-").map((word) => {return word.charAt(0).toUpperCase() + word.slice(1)}).join(" ")} en stock | Maison du Revêtement`;
        document.getElementsByTagName("meta")[2].content= `Consulter tous les ${gamme.replaceAll("-", " ")} en stock sélectionnés par Maison du Revêtement, avec leur tarif notamment.`;
    }, [gamme]);

    return (
        <>
            { pageIsLoading ?
                <div className="page-spinner">
                    <div className="page-spinner_interieur">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            :
            <>
                <header className={`header header_destockage_${gamme}`}>
                    <Header />
                    <TitreGammes titre={`${gamme} en stock`}/>
                </header>
                <main className="destockage">
                    <section className="destockage_titre">
                        <span>Maison du Revêtement propose une gamme de {gamme.replaceAll("-", " ")} disponible à tout petit prix en stock dans son entrepôt de Villeneuve-la-Garenne.</span>
                        <span>Parfait pour vos travaux rapides ou pour les budgets maitrisés.</span>
                        <div className="destockage_boutons">
                            <>{RenderSwitch(gamme)}</>
                        </div>
                    </section>
                    <>
                        { selectedCards.length > 0 ?
                            <span className="nb_articles" id="nb_articles">{selectedCards.length + " articles affichés !"}</span>
                        :
                            <span className="nb_articles" id="nb_articles">Aucun article ne correspond à votre recherche ! Nous ajouterons des produits prochainement.</span>
                        }
                        <section className="listeProduits">
                            { selectedCards.length > 0 && selectedCards.map((card) => {
                                return (
                                    <div className="cardArticle" key={card.id}>
                                        <Link to={"/gamme/categorie/sous-categorie/article/id=" + card.id} className="cardArticle_photo">
                                            <img src={(card.img_url).replace("image_256", "image_512")} alt={card.barcode}></img>
                                            { card.additional_photo !== false ?
                                                <img className="cardArticle_photo_deux" src={(card.additional_photo).replace("image_256", "image_512")} alt={card.barcode}></img>
                                            :
                                                <img className="cardArticle_photo_deux" src={(card.img_url).replace("image_256", "image_512")} alt={card.barcode}></img>
                                            }
                                            { card.id === 21466 || card.id === 21468 || card.id === 21469 || card.id === 28166 || card.id === 28168 || card.id === 28169 || card.id === 28170 ?
                                                <div className="bullePromo">PRIX USINE</div>
                                            :
                                                <>
                                                    { card.discount !== 0 ?
                                                        <div className="bullePromo">- {card.discount} %</div>     
                                                    :
                                                        null
                                                    }
                                                </>
                                            }
                                            { card.discount !== 0 ?
                                                <div className="bullePromo">- {card.discount} %</div>     
                                            :
                                                null
                                            }
                                            { card.provenance === "F" ?
                                                <div className="drapeau">
                                                    <img src={DrapeauFrance} alt="drapeau de la France"/>
                                                </div>        
                                            :
                                                null
                                            }
                                            { card.provenance === "E" ?
                                                <div className="drapeau">
                                                    <img src={DrapeauEspagne} alt="drapeau de l'Espagne"/>
                                                </div>        
                                            :
                                                null
                                            }
                                            { card.provenance === "I" ?
                                                <div className="drapeau">
                                                    <img src={DrapeauItalie} alt="drapeau de l'Italie"/>
                                                </div>        
                                            :
                                                null
                                            }
                                            { card.stock === true || card.quantityInStock >= 10 ?
                                                <div className="bulleStock">en stock</div>
                                                :
                                                <></>
                                            }
                                            { card.provenance === "F" ?
                                                <div className="drapeau">
                                                    <img src={DrapeauFrance} alt="drapeau de la France"/>
                                                </div>        
                                            :
                                                null
                                            }
                                        </Link>
                                        <div className="cardArticle_info">
                                            <Link to={"/gamme/categorie/sous-categorie/article/id=" + card.id} className="cardArticle_info_nom">
                                                {card.barcode}
                                            </Link>
                                            <p className="misEnAvant_bloc_article_info_cat">
                                                { card.category.display_name.split(" / ")[1] } { card.category.display_name.split(" / ")[2] !== undefined ? " / " + card.category.display_name.split(" / ")[2] : null }
                                            </p>
                                            { card.discount !== 0 || card.id === 21466 || card.id === 21468 || card.id === 21469 || card.id === 28166 || card.id === 28168 || card.id === 28169 || card.id === 28170 ?
                                                <div className="cardArticle_info_prix colorRed">
                                                    <p className="cardArticle_info_prix_gauche">
                                                        {(card.price_without_taxes * (1 - (card.discount / 100))).toFixed(2) + " €HT /m²"}
                                                    </p>
                                                    <p className="cardArticle_info_prix_droite">
                                                        {"(" + ((card.price_without_taxes * (1 - (card.discount / 100))) * 1.2).toFixed(2) + " €TTC /m²)"}
                                                    </p>
                                                </div>
                                            :
                                                <div className="cardArticle_info_prix">
                                                    <p className="cardArticle_info_prix_gauche">
                                                        {(card.price_without_taxes * (1 - (card.discount / 100))).toFixed(2) + " €HT /m²"}
                                                    </p>
                                                    <p className="cardArticle_info_prix_droite">
                                                        {"(" + ((card.price_without_taxes * (1 - (card.discount / 100))) * 1.2).toFixed(2) + " €TTC /m²)"}
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </section>
                    </>
                </main>
                <Plus />
                <Footer />
                <BoutonScrollToTop />
            </>
            }
        </>
    )
}